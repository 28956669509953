import 'lazysizes';
var html_classes = document.documentElement.classList;
html_classes.remove("no-js");
html_classes.add("js");
$().ready(function(){    
    setupSlideMenu();
    $('#map-click').click(function(){
        // shield the map on mobile for easy scrolling
        $(this).hide();
    });
    // scroller functions
    $("main .banners").each(function(){
        var $wrapper = $(this);
        var $items = $(this).find(".banner");
        var $nav_wrap = $(this).find(".banner-navigation");
        setHeight($items, $wrapper);
        scrollSetup($items, $nav_wrap, '.banner-nav-item');
    });
    $(".t-scroller .testimonials").each(function(){
        var $wrapper = $(this).find(".scrollwrap");
        var $items = $(this).find(".scroller .item");
        var $nav_wrap = $(this).find(".scroll-nav");
        setHeight($items, $wrapper);
        scrollSetup($items, $nav_wrap, '.nav-item');
    });
    //FAQs expand
    $('.faq').each(function(index,el){
        var $el = $(el);
        smoothExpander($el, $el.find('.title'), $el.find('.content'));
    });
});
$(window).resize(function(){
    // set height on scrollers
    $("main .banners").each(function(){
        var $wrapper = $(this);
        var $items = $(this).find(".banner");
        setHeight($items, $wrapper);
    });
    $(".t-scroller .testimonials").each(function(){
        var $wrapper = $(this).find(".scrollwrap");
        var $items = $(this).find(".scroller .item");
        setHeight($items, $wrapper);
    });
});

export function setupSlideMenu(){
    $('#mobile-menu, .slide-menu h3').click(function(){
        $("body").toggleClass('slide-menu-active');
    });
    $(".slide-menu").on('click','.expand',function(e) {
        e.stopPropagation();
        $(this).closest('li').toggleClass('expand-active');
    });
    $(".slide-menu li").each(function(index, element) {
        var $el = $(element);
        if ($el.find('ul').length > 0) {
            var expander = $('<span class="expand"><i class="nav-arrow"></i></span>');
            $el.append(expander);
            expander.click(function(e) {
                e.stopPropagation();
                $el.toggleClass('expand-active');
            });
        }
    });
}

/* set height */
function setHeight($items, $wrapper){
    var max = 0;
    $items.each(function(){
        var item = $(this);
        var clone = item[0].cloneNode(true);
        $(clone).css("position", "static", "height", "auto");
        item.after(clone);
        var height = Math.ceil($(clone).outerHeight());
        if (height > max){
            max = height;
        }
        clone.remove();
    });
    $wrapper.css("min-height", max+"px");
}
// Scrollers
var rotInt;
function scrollSetup($items, $nav_wrap, nav_class){
    if($items.length > 1)  {
        var $nav_items = $nav_wrap.find(nav_class);
        rotInt = setInterval(function(){swap($items, $nav_items);}, 5000);
        scrollNav($nav_items, $items);
        $nav_wrap.show();
    }
}
function swap($items, $nav_items){
    var $scroller = $items.parent();
    var $nav_wrap = $nav_items.parent();

    var $oldb = $scroller.find(".active");
    var $newb = $scroller.find(".active").next();
    var $lastb = $items.last();

    if (! $lastb.hasClass('active')){
        $oldb.removeClass('active');
        $newb.addClass('active');
        var $index = $newb.index();
        /* nav */
        $nav_wrap.find(".active").removeClass("active");
        $nav_items.eq($index).addClass("active");
    } else {
        $oldb.removeClass("active");
        $items.first().addClass("active");
        /* banner nav */
        $nav_wrap.find(".active").removeClass("active");
        $nav_items.first().addClass("active");
    }
}
function scrollNav($nav_items, $items){
    $nav_items.each(function(index,el){
        $(el).click(function(){
            // nav
            $(this).siblings().removeClass("active");
            $(this).addClass("active");
            var $index = $(this).index();
            // scroll items
            $items.removeClass('active');
            $items.eq($index).addClass('active');
            // reset
            clearInterval(rotInt);
            rotInt = setInterval(function(){swap($items, $nav_items);}, 5000);
        });
    });
}

// FAQs
function smoothExpander($element, $trigger, $target){
// element = faq, trigger = what you click, target = the item that changes maxHeight
    $trigger.click(function (){
        // unset others
        $element.siblings().removeClass('active');
        $element.siblings().find('.content').css('maxHeight', '0px');
        // toggle this element
        $element.toggleClass('active');
        if ($element.hasClass('active')) {
            //Create clone without restricted height and see what natural height is.
            var clone = $target.clone().css('maxHeight','none').insertAfter($element);
            var clone_height = clone.height() + 60; // allow for a bit extra
            clone.remove();

            $target.css('maxHeight', clone_height+'px');
        } else {
            $target.css('maxHeight', '0px');
        }
    });
}

//add style attribute with background image when lazyload event fired
// Setup background-image elements with class="lazyload" and data-bg-url-"https://domain.com/image/url/file.jpg".
// When they become visible, the lazy loader will fire this, and we will update the background image style.
// A low res fallback background image style could be set, so that something is visible before this triggers.
document.addEventListener('lazybeforeunveil', function(e){
    var container = e.target;
    var bgUrl = container.dataset.bgUrl;
    if (bgUrl) {
        container.style.backgroundImage = "url('" + bgUrl + "')";
        delete container.dataset.bgUrl;
    }
});